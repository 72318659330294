import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignOut, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import axios from "axios";
import { BASE_URL } from "constants/api.constant";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    fetch(BASE_URL + "users/login", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.data.token) {
          const token = result.data.token;
          dispatch(onSignInSuccess(token));
		  dispatch(setUser({
			avatar: '',
			userName: 'SERKAN',
			authority: ['ÇAMKERTEN'],
			email: result.data.emailAddress
		}))
          const redirectUrl = query.get(REDIRECT_URL_KEY);
          navigate(
            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
          );
          return {
            status: "success",
            message: "",
          };
        } else {
          return {
            status: "failed",
            message: result?.data?.message || result?.data?.message.toString(),
          };
        }
      })
      .catch((error) => {
        return {
          status: "failed",
          message: error.message || error.toString(),
        };
      });
    // console.log(data)
    // if(data.data.token){
    // 	const token = data.data.token;
    // 	dispatch(onSignInSuccess(token))
    // 	const redirectUrl = query.get(REDIRECT_URL_KEY)
    // 	navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
    // 	return {
    // 		status: 'success',
    // 		message: ''
    // 	}
    // }

    // try {
    // 	const resp = await apiSignIn(values)
    // 	if (resp.data) {
    // 		const { token } = resp.data
    // 		dispatch(onSignInSuccess(token))
    // 		if(resp.data.user) {
    // 			dispatch(setUser(resp.data.user || {
    // 				avatar: '',
    // 				userName: 'Anonymous',
    // 				authority: ['USER'],
    // 				email: ''
    // 			}))
    // 		}
    // 		const redirectUrl = query.get(REDIRECT_URL_KEY)
    // 		navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
    //         return {
    //             status: 'success',
    //             message: ''
    //         }
    // 	}
    // } catch (errors) {
    // 	return {
    //         status: 'failed',
    //         message: errors?.response?.data?.message || errors.toString()
    //     }
    // }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    // await apiSignOut()
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;
