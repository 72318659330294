import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY, REQUEST_HEADER_CONTENT_TYPE } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import { Notification, toast } from 'components/ui'

const openNotification = (type, message) => {
    toast.push(
        <Notification title={type.charAt(0).toUpperCase() + type.slice(1)} type={type}>
            {message}
        </Notification>
    )
}

const unauthorizedCode = [401]

const BaseService = axios.create({
    timeout: 300000,
    baseURL: appConfig.apiPrefix,
})

BaseService.interceptors.request.use(config => {

    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    const persistData = deepParseJson(rawPersistData)
    
    const accessToken = persistData.auth.session.token
    if (accessToken) {
        config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
    }
    if(config.url.includes('siteSettings') || config.url.includes('clinic')){
        config.headers[REQUEST_HEADER_CONTENT_TYPE] = `multipart/form-data`
    }


    return config
}, error => {
    return Promise.reject(error)
})

BaseService.interceptors.response.use(
    response => {
        if(response.config.method !== "get")
            openNotification('success', response.data.message)
    },

    error => {
        const { response } = error
        if(error.response.data.message !== null) {
            openNotification('danger', error.response.data.message)
        }
        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }
        return Promise.reject(error)
    }
)

export default BaseService