import { BASE_URL } from 'constants/api.constant'
import BaseService from './BaseService'

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param).then(response => {
                resolve(response)
            }).catch(errors => {
                reject(errors)
            })
        })
    }
}

export async function createSiteSettings (url, data) {
    return ApiService.fetchData({
        url: url,
        method: 'post',
        data
    })
}

export async function createApiService (query, data) {
    return ApiService.fetchData({
        url: BASE_URL + query,
        method: 'post',
        data
    })
}

export async function updateApiService (query, data) {
    console.log(BASE_URL + query)
    return ApiService.fetchData({
        url: BASE_URL + query,
        method: 'put',
        data
    })
}

export async function deleteApiService (query) {
    return ApiService.fetchData({
        url: BASE_URL + query,
        method: 'delete'
    })
}

export async function getApiService (query) {
    return ApiService.fetchData({
        url: BASE_URL + query,
        method: 'get'
    })
}

export default ApiService